import { useEffect } from "react";
import { useHistory } from "react-router";

export default function NoPage() {
    const gotoPage = useHistory();

    useEffect(() => {
        gotoPage.push("/log_in");
    }, [gotoPage])

    return (
        <div></div>
    );
}