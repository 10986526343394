import "./alerts.css";
import warningRed from "../../images/warning_red@3x.png";

export const AlertFormError = (props) => {
    return (
        <div className={props.cssClass+" alert-form border-red bg-red px-1 py-3 border-radius"} style={{display: props.showError}}>
            <div className="col-auto d-flex align-items-center">
                <img src={warningRed} alt="" style={{width: "40px", height: "40px"}}/>
            </div>
            <div className="col">
                <div className="row">
                    <div className="col p-0">
                        <p className="c-red font-family-roboto font-weight-bold font-italic font-size-16 m-0">
                            {props.title}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col p-0">
                        <p className="c-opale font-family-roboto font-weight-medium font-size-14 m-0">
                            {props.children}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}