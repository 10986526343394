import "./header.css";
import logo from "../../images/Group@3x.png"

export default function HeaderLogo() {
    return (
        <div className="row m-0" style={{flex: "0 1"}}>
            <div className="col-12">
                <img className="m-4 h-auto logo" alt="" src={logo} />
            </div>
        </div>
    );
}