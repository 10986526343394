import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { ButtonPrimaryRipple } from "./commons/buttons";
import { TextBlock } from "./commons/texts";
import { change } from "../api/fetch";
import { Password } from "./commons/inputs";

export const HeaderChange = () => {
    const { t } = useTranslation();

    return (
        <div className="col mb-3">
            <TextBlock family="montserrat" weight="bold" size="40" color="blue">
                {t("change.header.title")}
            </TextBlock>
        </div>
    );
}

export const ContentChange = () => {
    const { t } = useTranslation();

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [enableButton, setEnableButton] = useState("disabled")
    
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const gotoPage = useHistory();

    const fetchChange = useCallback(async () => {
        const method = "POST";
        const body = new FormData();

        const params = (new URL(document.location)).searchParams

        body.append("new_password", password);
        body.append("cod_change_password", params.get('hashcode'))

        const result = await change({method, body});

        setResponse(result);
    }, [password])

    useEffect(() => {
        if(response) {
            gotoPage.push("/log_in")
        }
    }, [response, gotoPage])

    useEffect(() => {
        if(loading) {
            fetchChange();
            setLoading(false);
        }
    }, [loading, fetchChange])

    useEffect(() => {
        if(password !== "" && password.length > 4 && password === repeatPassword) {
            setEnableButton("")
        } else setEnableButton("disabled")
    }, [password, repeatPassword])

    return (
        <form className="col text-center mb-4">
            <Password cssClass="mb-3" setPassword={(value) => setPassword(value)}/>
            <Password cssClass="mb-4" setPassword={(value) => setRepeatPassword(value)} role="repeat"/>
            <ButtonPrimaryRipple cssClass=" px-4" click={() => setLoading(true)} disabled={enableButton}>
                {t("change.content.button")}
            </ButtonPrimaryRipple>
        </form>
    );
}