import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./inputs.css";

export const Username = (props) => {
    const { t } = useTranslation();

    return (
        <div className="w-100">
            <input 
                type="text" 
                className={props.cssClass+" d-inline font-family-roboto font-weight-medium font-size-14 c-opale py-2 px-3 border border-radius"} 
                placeholder={t("commons.inputs.username.placeholder")}
                onChange={(e) => props.setUsername(e.target.value)}
            />
        </div>
    );
}

export const Password = (props) => {
    const { t } = useTranslation();
    
    const [showPassword, setShowPassword] = useState(false);
    
    return (
        <div className=" w-100 position-relative">
            <input 
                type={showPassword ? "text" : "password"} 
                className={props.cssClass+" d-inline font-family-roboto font-weight-medium font-size-14 c-opale py-2 px-3 border border-radius"}
                placeholder={props.role === "repeat" ? t("commons.inputs.password.placeholder_2") : t("commons.inputs.password.placeholder_1")}
                onChange={(e) => props.setPassword(e.target.value)}
            />
            <span 
                className="d-flex align-items-center border-radius-right font-family-roboto font-weight-medium font-size-14 bg-white c-blue position-absolute pointer" 
                style={{top: "1px", right: "calc(50% - 134px)", height: "32px", padding: "0 10px"}}
                onClick={() => setShowPassword(!showPassword)}
            >
                {showPassword ? t("commons.inputs.password.hide") : t("commons.inputs.password.show")}
            </span>
        </div>
    );
}