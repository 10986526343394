import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { login } from "../api/fetch"
import { Username, Password } from "./commons/inputs";
import { AlertFormError } from "./commons/alerts";
import { ButtonPrimaryRipple } from "./commons/buttons";
import { TextBlock, TextInline } from "./commons/texts";

export const HeaderLogin = () => {
    const { t } = useTranslation();

    return (
        <div className="col text-center mb-2">
            <TextBlock family="montserrat" weight="bold" size="74" color="blue">
            {t("login.header.title_1")}
            </TextBlock>
        </div>
    );
}

export const ContentLogin = () => {
    const { t } = useTranslation();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [enableButton, setEnableButton] = useState("disabled")

    const [response, setResponse] = useState(null);
    const [showError, setShowError] = useState("none");
    const [loading, setLoading] = useState(false);


    const fetchLogin = useCallback(async () => {
        const method = "POST";
        const body = new FormData();
        body.append("username", username);
        body.append("password", password);

        setShowError("none");
        const result = await login({method, body});

        setResponse(result);
    }, [username, password])

    useEffect(() => {
        if(response && response.code === 200) {
            localStorage.setItem("i18nextLng", response.data.language);
            window.location = response.data.application_url;
        } else if(response) setShowError("flex");
    }, [response])

    useEffect(() => {
        if(loading) {
            fetchLogin();
            setLoading(false);
        }
    }, [loading, fetchLogin])

    useEffect(() => {
        if(username !== "" && password !== "") {
            setEnableButton("")
        } else setEnableButton("disabled")
    }, [username, password])

    return (
        <form className="col text-center mb-4">
            <Username cssClass="mb-3" setUsername={(value) => setUsername(value)} />
            <Password cssClass="mb-4" setPassword={(value) => setPassword(value)} />
            <AlertFormError cssClass="mb-4 mx-auto text-left" showError={showError} title={t("login.content.error.title")}>
                {t("login.content.error.subtitle")}
            </AlertFormError>
            <ButtonPrimaryRipple cssClass=" px-5" click={() => setLoading(true)} disabled={enableButton}>
                {t("login.content.button")}
            </ButtonPrimaryRipple>
        </form>
    );
}

export const FooterLogin = () => {
    const { t } = useTranslation();
    const gotoPage = useHistory();

    return (
        <div className="col text-center">
            <TextInline family="roboto" weight="medium" size="14" color="opale">
                {t("login.footer.title")}&nbsp;
            </TextInline>
            <TextInline cssClass="pointer" family="roboto" weight="medium" size="14" color="blue" click={() => gotoPage.push("/forgot")}>
                {t("login.footer.link")}
            </TextInline>
        </div>
    );
}