import { useEffect, useState } from "react";
import "./buttons.css";

export const ButtonPrimary = (props) => {
  const onClick = (e) => {
    e.preventDefault();
    props.click();
  }
  
  return (
    <button 
      type="submit" 
      className={props.cssClass+" btn bg-blue c-white shadow"}
      onClick={onClick}
    >
      {props.children}
    </button>
  )
}

export const ButtonPrimaryRipple = (props) => {
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    const rect = e.target.getBoundingClientRect();
    setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    props.click(e);
  }

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  return (
    <button
      className={props.cssClass+" btn bg-blue c-white position-relative overflow-hidden shadow"}
      disabled={props.disabled}
      onClick={onClick}
    >
      {isRippling ? (
        <span
          className="ripple"
          style={{
            left: coords.x,
            top: coords.y
          }}
        />
      ) : (
        ''
      )}
      {props.children}
    </button>
  );
};