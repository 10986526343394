import React from "react";
import "./login.css";
import HeaderLogo from "../components/commons/header";
import { HeaderLogin, ContentLogin, FooterLogin } from "../components/login";

export default function Login() {
  return (
    <div className="container-fluid p-0 overflow-hidden full-page background login">
      <div className="h-100 w-100 overflow-auto d-flex flex-column">
        <HeaderLogo/>
        <div className="d-flex col-sm-7 align-items-center justify-content-center pb-5 mb-5" style={{flex: "1 1"}}>
          <div className="row flex-column">
            <HeaderLogin/>
            <ContentLogin/>
            <FooterLogin/>
          </div>
        </div>
      </div>
    </div>
  );
}