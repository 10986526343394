import React from "react";
import "./send.css";
import HeaderLogo from "../components/commons/header";
import { HeaderSend, FooterSend } from "../components/send";

export default function Send() {
  return (
    <div className="container-fluid p-0 overflow-hidden full-page background send">
      <div className="h-100 w-100 overflow-auto d-flex flex-column">
        <HeaderLogo/>
        <div className="d-flex col-sm-7 align-items-center justify-content-center pb-5 mb-5" style={{flex: "1 1"}}>
          <div className="row flex-column">
            <HeaderSend/>
            <FooterSend/>
          </div>
        </div>
      </div>
    </div>
  );
}