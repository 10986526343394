import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { Username } from "./commons/inputs";
import { ButtonPrimaryRipple } from "./commons/buttons";
import { TextBlock, TextInline } from "./commons/texts";
import { forgot } from "../api/fetch";
import { AlertFormError } from "./commons/alerts";

export const HeaderForgot = () => {
    const { t } = useTranslation();

    return (
        <div className="col mb-3">
            <TextBlock family="montserrat" weight="bold" size="40" color="blue">
                {t("forgot.header.title")}
            </TextBlock>
            <TextBlock family="montserrat" weight="regular" size="32" color="opale">
                {t("forgot.header.subtitle_1")}
            </TextBlock>
            <TextBlock family="montserrat" weight="regular" size="32" color="opale">
                {t("forgot.header.subtitle_2")}
            </TextBlock>
        </div>
    );
}

export const ContentForgot = () => {
    const { t } = useTranslation();

    const [username, setUsername] = useState("");
    const [enableButton, setEnableButton] = useState("disabled")
    
    const [response, setResponse] = useState(null);
    const [showError, setShowError] = useState("none");
    const [loading, setLoading] = useState(false);

    const gotoPage = useHistory();

    const fetchForgot = useCallback(async () => {
        const method = "POST";
        const body = new FormData();
        body.append("login", username);

        setShowError("none");
        const result = await forgot({method, body});

        setResponse(result);
    }, [username])

    useEffect(() => {
        if(response && response.code === 200) {
            gotoPage.push("/send")
        } else if(response) setShowError("flex");
    }, [response, gotoPage])

    useEffect(() => {
        if(loading) {
            fetchForgot();
            setLoading(false);
        }
    }, [loading, fetchForgot])

    useEffect(() => {
        if(username !== "") {
            setEnableButton("")
        } else setEnableButton("disabled")
    }, [username])

    return (
        <form className="col text-center mb-4">
            <Username cssClass="mb-4" setUsername={(value) => setUsername(value)}/>
            <AlertFormError cssClass="mb-4 mx-auto text-left" showError={showError} title={t("login.content.error.title")}>
                {t("login.content.error.subtitle")}
            </AlertFormError>
            <ButtonPrimaryRipple cssClass=" px-4" click={() => setLoading(true)} disabled={enableButton}>
                {t("forgot.content.button")}
            </ButtonPrimaryRipple>
        </form>
    );
}

export const FooterForgot = () => {
    const { t } = useTranslation();
    const gotoPage = useHistory();

    return (
        <div className="col text-center">
            <TextInline family="roboto" weight="medium" size="14" color="opale">
                {t("forgot.footer.title")}&nbsp;
            </TextInline>
            <TextInline cssClass="pointer" family="roboto" weight="medium" size="14" color="blue" click={() => gotoPage.push("/")}>
                {t("forgot.footer.link")}
            </TextInline>
        </div>
    );
}