export const TextBlock = ({children, family, weight, size, color, click}) => {
    return (
        <p className={`d-block font-family-${family} font-weight-${weight} font-size-${size} c-${color} m-0`} onClick={click}>
            {children}
        </p>
    );
}

export const TextInline = ({children, family, weight, size, color, click, cssClass}) => {
    return (
        <p className={`${cssClass} d-inline font-family-${family} font-weight-${weight} font-size-${size} c-${color} m-0`} onClick={click}>
            {children}
        </p>
    );
}