import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { TextBlock, TextInline } from "./commons/texts";

export const HeaderSend = () => {
    const { t } = useTranslation();

    return (
        <div className="col mb-2">
            <TextBlock family="montserrat" weight="bold" size="42" color="blue">
                {t("send.header.title_1")}
            </TextBlock>
            <TextBlock family="montserrat" weight="bold" size="42" color="blue">
                {t("send.header.title_2")}
            </TextBlock>
            <TextBlock family="montserrat" weight="regular" size="32" color="opale">
                {t("send.header.subtitle_1")}
            </TextBlock>
            <TextBlock family="montserrat" weight="regular" size="32" color="opale">
                {t("send.header.subtitle_2")}
            </TextBlock>
        </div>
    );
}

export const FooterSend = () => {
    const { t } = useTranslation();
    const gotoPage = useHistory();

    return (
        <div className="col">
            <TextInline family="roboto" weight="medium" size="14" color="opale">
                {t("send.footer.title")}&nbsp;
            </TextInline>
            <TextInline cssClass="pointer" family="roboto" weight="medium" size="14" color="blue" click={() => gotoPage.push("/forgot")}>
                {t("send.footer.link")}
            </TextInline>
        </div>
    );
}