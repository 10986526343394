import { Suspense } from "react";
import AppContainer from "./container/AppContainer"

function App() {
  return (
    <Suspense fallback="loading">
      <AppContainer/>
    </Suspense>
  );
}

export default App;