import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Forgot from "../pages/Forgot";
import Send from "../pages/Send";
import Login from "../pages/Login";
import Change from "../pages/Change";
import NoPage from "../pages/NoPage";

export default function AppContainer() {
    return (
        <Router>
            <Switch>
                <Route path="/resetpassword">
                    <Change />
                </Route>
                <Route path="/send">
                    <Send />
                </Route>
                <Route path="/forgot">
                    <Forgot />
                </Route>
                <Route path="/log_in">
                    <Login />
                </Route>
                <Route path="*">
                    <NoPage />
                </Route>
            </Switch>
        </Router>
    );
}