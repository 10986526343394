import { fetchData } from "./model";

export async function login(params) {
    return await fetchData("/login/", params);
}

export async function forgot(params) {
    return await fetchData("/login/forgot-password", params);
}

export async function change(params) {
    return await fetchData("/login/change-password", params);
}